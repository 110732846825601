.market-watch-container {
    background-color: var(--light-gray-3);
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    text-align: left;
    padding: 9px;
}

.market-watch-container .blocks {
    display: flex;
    flex-wrap: wrap;
}

.market-watch-indices {
    display: flex;
    width: auto;
    align-items: center;
    font-size: 14px;
    padding: 15px;
    cursor: pointer;
}

.market-watch-container .title {
    color: var(--primary-text);
    font-weight: 600;
    padding-right: 12px;
}

.market-watch-container .sub-title {
    color: var(--primary-text);
}

.dark .market-watch-container {
    background-color: #1f222c;
}

.dark .market-watch-container .title {
    color: var(--primary-text-dark);
}

.dark .market-watch-container .sub-title {
    color: var(--secondary-text-dark);
}

.market-watch-container .subtitle {
}

.market-watch-container .stock-container {
    display: flex;
    width: 100%;
    align-items: center;
    font-size: 14px;
    min-width: 212px;
    padding-left: 20px;
}

.market-watch-container .stock-container img {
    width: 40px;
    height: 40px;
}

.market-watch-container .stock-container .block {
    padding: 12px;
    width: max-content;
}

.market-watch-container .stock-container .block.positive {
    color: var(--light-green-3);
}

.market-watch-container .stock-container .block.negative {
    color: var(--light-red-2);
}

.summary-container {
    text-align: left;
}

.summary-detail-container {
    display: flex;
    gap: 45px;
}

.summary-container .title,
.summary-detail-container {
    color: var(--dark-4);
}

.summary-detail-container .item {
    width: 179px;
    height: 63px;
}

.summary-detail-container .item .label {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
}

.summary-detail-container .item .value {
    font-weight: 700;
    font-size: 32px;
    line-height: 42px;
}

.summary-detail-container .item .value.positive {
    color: var(--light-green-3);
}

.chart-detail-container {
    /* justify-content: center; */
    align-items: center;
    display: flex;
    /* align-content: center; */
    flex-direction: column;
    width: 100%;
}

.chart-detail-container .item {
    width: 244px;
    height: 62px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: flex-start;
    align-items: center;

    background: var(--light-gray-3);
    box-shadow: 4px 4px 20px -10px rgba(0, 0, 0, 0.1);
    border-radius: 12px;

    padding-left: 12px;
    padding-right: 12px;
    margin-bottom: 12px;

    cursor: pointer;
}

.chart-detail-container .item .color-box {
    width: 32px;
    height: 32px;
    background: #3268f6;
    box-shadow: 0px 3.5px 5.5px rgba(0, 0, 0, 0.02);
    border-radius: 8px;
}

.chart-detail-container .item .content {
    width: 70%;
    text-align: left;
}

.chart-detail-container .item .content .name {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--dark-4);
}

.chart-detail-container .item .content .value {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--light-gray-4);
}

/* dark mode */
.dark .market-watch-container .stock-container .block.positive {
    color: #8effd1;
}

.dark .market-watch-container .stock-container .block.negative {
    color: var(--light-red-2);
}

.outstanding-container {
    text-align: left;
    color: var(--dark-4);
}

.outstanding-container .card-block {
    width: 195px;
    height: 152px;
    display: flex;
    text-align: left;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding-left: 20px;
    padding-right: 20px;
    gap: 10px;
    border-radius: 12px;
}

.outstanding-container .card-block .title {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
}

.outstanding-container .card-block .currency {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
}

.outstanding-container .card-block .value {
    font-weight: 700;
    font-size: 24px;
    line-height: 42px;
}

.outstanding-container .card-block {
    color: var(--light-gray);
    background: var(--light-gray-3);
}

.outstanding-container .card-block.active {
    background: var(--blue-1);
    color: white;
}

.outstanding-container .card-block .title,
.outstanding-container .card-block .currency {
    color: var(--dark-4);
}
.outstanding-container .card-block .value {
    color: var(--dark-4);
}

.outstanding-container .card-block.active .title,
.outstanding-container .card-block.active .currency,
.outstanding-container .card-block.active .value {
    color: white;
}

.dark .outstanding-container {
    color: white;
    text-align: left;
}

.dark .outstanding-container .card-block {
    color: var(--light-gray);
    background: var(--dark-2);
}

.dark .outstanding-container .card-block.active {
    background: #00218f;
    color: white;
}

.dark .summary-container .title,
.dark .summary-detail-container {
    color: white;
}

.dark .summary-detail-container .item .value.positive {
    color: var(--light-green-1);
}

.dark .chart-detail-container .item .name {
    color: white;
}

.dark .chart-detail-container .item .value {
    color: var(--light-gray);
}

.dark .chart-detail-container .item {
    background: #1f222c;
}

.dark .outstanding-container .card-block .title,
.dark .outstanding-container .card-block .currency {
    color: var(--light-gray);
}
.dark .outstanding-container .card-block .value {
    color: var(--light-gray-4);
}

.dark .outstanding-container .card-block.active .title,
.dark .outstanding-container .card-block.active .currency,
.dark .outstanding-container .card-block.active .value {
    color: white;
}

.event-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: var(--dark-4);
}

.event-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding-top: 16px;
}

.event-container .item {
    width: 235px;
    height: 358.49px;
}

.event-container .item:hover {
    cursor: pointer;
    opacity: 0.6;
}

.event-container .item .sub-title {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: var(--dark-4);
    padding-top: 19px;
}

.event-container .item .title {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: var(--dark-4);
    padding-top: 15px;
}

.event-container .item .desc {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: var(--light-gray-4);
    padding-top: 8px;
}

.dark .event-title,
.dark .event-container .item .title {
    color: white;
}

.dark .event-container .item .sub-title,
.dark .event-container .item .desc {
    color: var(--light-gray);
}

.annoucements-container {
    width: 414px;
    background: var(--light-gray-3);
    box-shadow: 4px 4px 20px -10px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    padding: 20px;
}

.annoucements-container .title {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: var(--dark-4);
}

.annoucements-container .list {
    padding-top: 12px;
}

.annoucements-container .list .item {
    display: flex;
    align-items: center;
    padding-bottom: 16px;
}

.annoucements-container .list .date {
    width: 57.64px;
    height: 57px;
    background: #f5f578;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--black-1);
    padding: 15px;
}

.annoucements-container .list .day {
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
}

.annoucements-container .list .month {
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
}

.annoucements-container .list .title {
    padding-left: 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--dark-4);
}

.annoucements-container .list .title:hover {
    text-decoration: underline;
    cursor: pointer;
}

.dark .annoucements-container {
    background: var(--dark-2);
}

.dark .annoucements-container .title {
    color: var(--light-gray);
}

.research-reports-container .title {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: var(--dark-4);
    padding-bottom: 16px;
}

.report-blocks-container .item {
    padding-bottom: 16px;
}

.report-blocks-container .item .date {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: var(--dark-4);
    padding-bottom: 4px;
}

.report-blocks-container .item .title {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: var(--dark-4);
    padding-bottom: 8px;
}

.report-blocks-container .item .desc {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: var(--light-gray-4);
}

.dark .research-reports-container .title {
    color: var(--light-gray);
}

.dark .report-blocks-container .item .date {
    color: var(--light-gray);
}

@media screen and (max-width: 600px) {
    .market-watch-container {
        flex-direction: column;
        align-items: flex-start;
    }

    .dark .outstanding-container .card-block {
        width: 100%;
        height: 100%;
    }

    .summary-detail-container {
        flex-direction: column;
    }

    .market-watch-container .blocks {
        flex-direction: column;
        width: inherit;
    }

    .annoucements-container {
        width: 100%;
    }

    .outstanding-container .card-block {
        width: 100%;
    }

    .event-container .item {
        width: 100%;
        height: auto;
    }
    .event-container .item > img {
        width: 100%;
    }

    .market-watch-container .stock-container .block.label {
        width: 50%;
    }
}
