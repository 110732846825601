/*.App {*/
/*    text-align: center;*/
/*}*/

/*.App-logo {*/
/*    height: 40vmin;*/
/*    pointer-events: none;*/
/*}*/

/*@media (prefers-reduced-motion: no-preference) {*/
/*    .App-logo {*/
/*        animation: App-logo-spin infinite 20s linear;*/
/*    }*/
/*}*/

/*.App-header {*/
/*    background-color: #282c34;*/
/*    min-height: 100vh;*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*    font-size: calc(10px + 2vmin);*/
/*    color: white;*/
/*}*/

/*.App-link {*/
/*    color: #61dafb;*/
/*}*/
/* Chrome, Safari, Edge, Opera */
input[type="number"].inputNoNum::-webkit-outer-spin-button,
input[type="number"].inputNoNum::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


/* Firefox */
input[type="number"].inputNoNum {
    -moz-appearance: textfield;
}

.rightColItem  button{
    min-width: 5em !important;
}
.dropdown-sub-layer {
    display: none;
    width: 14rem;
    text-align: left;
    margin-left: 0.5em;
    margin-right: 0.5em;
    margin-bottom: 1em;
    margin-top: 0.5em;
    -webkit-animation: fadeInDown 0.5s;
    animation: fadeInDown 0.5s;
    animation-fill-mode: forwards;
    box-shadow: 0 0.15rem 1.75rem 0 rgb(58 59 69 / 15%);
}

.dropdown-outter-layer {
    width: 15rem;
    text-align: left;
    margin-left: 0.5em;
    margin-right: 0.5em;
    margin-bottom: 1em;
    margin-top: 0.5em;
    -webkit-animation: fadeInDown 0.5s;
    animation: fadeInDown 0.5s;
    animation-fill-mode: forwards;
    box-shadow: 0 0.15rem 1.75rem 0 rgb(58 59 69 / 15%);
}

.dropdown-outter-layer-shrink-view {
    width: 13rem;
    text-align: left;
    margin-left: 0.5em;
    margin-right: 0.5em;
    margin-bottom: 1em;
    margin-top: 0.5em;
    -webkit-animation: fadeInDown 0.5s;
    animation: fadeInDown 0.5s;
    animation-fill-mode: forwards;
    position: absolute;
    margin-left: 7rem;
    margin-top: -4.5rem;
}

.dropdown-outter-layer > dropdown-inner-item {

    box-shadow: 0 0.15rem 1.75rem 0 rgb(58 59 69 / 15%);
}

.dropdown-inner-item-third-level {
    padding: 0.3rem;
    padding-left: 1.5rem;
    margin: 0 0.5rem;
    display: block;
    color: #3a3b45;
    text-decoration: none;
    border-radius: 0.35rem;
    white-space: inherit;
    font-size: .95rem;
}

.dropdown-inner-item {
    padding: 0.5rem 1rem;
    margin: 0 0.5rem;
    display: block;
    color: #3a3b45;
    text-decoration: none;
    border-radius: 0.35rem;
    white-space: inherit;
    font-size: .95rem;
}

.dropdown-inner-item:hover {
    background-color: #eaecf4;
    padding: 0.5rem 1rem;
    margin: 0 0.5rem;
    display: block;
    color: #3a3b45;
    text-decoration: none;
    border-radius: 0.35rem;
    white-space: inherit;
    font-size: .95rem;

}

.dropdown-inner-item-third-level:hover {
    background-color: #eaecf4;

    display: block;
    color: #3a3b45;
    text-decoration: none;
    border-radius: 0.35rem;
    white-space: inherit;
    font-size: .95rem;

}

.nav-item:hover {
    cursor: pointer;

}

.arrow-down {
    float: right;
    border: solid black;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 0.2rem;
    margin-top: 0.5rem;
    /*margin-left: 1rem;*/
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.arrow-up {
    float: right;
    border: solid black;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 0.2rem;
    margin-top: 0.5rem;
    /*margin-left: 1rem;*/
    transform: rotate(270deg);
    -webkit-transform: rotate(220deg);
}

.arrow_div {
    display: contents;
}



input[type='checkbox'] {
    width: 1.1rem;
    height: 1.1rem;
    margin-right: .8rem;

}

input[type='radio'] {
    width: 1.1rem;
    height: 1.1rem;
    margin-right: .8rem;

}



.checkboxMarginLeft {
    margin-left: 1rem;
    margin-right: 0.4rem;
}

.checkboxMarginLeft .label {
    margin-left: 1rem;
    margin-right: 0.4rem;
}

.checkboxMarginRight {
    margin-left: 0.4rem;
    margin-right: 0.2rem !important;
}

/*input[type='checkbox']{*/
/*    margin:0.4rem;*/
/*}*/


.inFormTopNavBtnSelected {
    border: none;
    margin-left: 0.4rem;
    margin-right: 0.4rem;
    border-radius: 0.3rem;
    background-color: #344865;
    border-color: #344865;
    color: white;
    padding: 0.4rem;
    width: 18rem;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.inFormTopNavBtn {
    border: none;
    margin-left: 0.4rem;
    margin-right: 0.4rem;
    border-radius: 0.3rem;
    background-color: #909DAF;
    border-color: #909DAF;
    color: white;
    padding: 0.4rem;
    width: 18rem;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.generatePasswordBtn {
    cursor: pointer;
}

.btn_search_padding button {
    margin-right: .5em;
    margin-top: .5em;
}


.fullWidthSearch {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 21px;
    color: var(--dark-4);
    padding: 10px 32px 10px 0px;
}


.fullWidthSearch .label {
    padding-right: 15px;
    min-width: 90px;
    font-weight: 600;
    font-size: 1.2rem;
}



.fullWidthSearch input {
    font-size: 1.2rem !important;
}

select {
    font-size: 1.2rem !important;
}

@media screen and (max-width: 600px) {
    .fullWidthSearch {
        flex-direction: column;
        align-items: baseline;
        display: inherit;
    }
}

.fullWidthTable {
    width: 100%;
    table-layout: fixed;
    border: 1px solid grey;
    text-align: right;
}

.rdt_TableCol_Sortable{
    white-space : pre !important;
}

.btn-clear-table {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    height: 32px;
    width: 32px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    /*background-color: #344865;*/
    background-color: #343434;
    border: none;
}

.ipt-search-tbl {
    height: 32px;
    width: 200px;
    border-radius: 3px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid #e5e5e5;
    padding: 0 32px 0 16px;
    font-size: .9rem;

}


.spinner-animated {
    margin: 16px;
    animation: rotate360 1s linear infinite;
    transform: translateZ(0);
    border-top: 2px solid grey;
    border-right: 2px solid grey;
    border-bottom: 2px solid grey;
    border-left: 4px solid black;
    background: transparent;
    width: 80px;
    height: 80px;
    border-radius: 50%;
}

.tableItemsHlink {
    text-align: center;
    color: #344865;
}

.center-align-text {
    text-align: center;
}

input[type=tel] {
    width: 100% !important;

}

.react-tel-input-full {
    font-size: 1.2rem !important;
}

.mandatory-red {
    color: red;
    position: absolute;
}

.mandatory-red-inside {
    color: red;
    position: absolute;
    margin-left: -0.7rem;
}

.rdt_Pagination {
    font-size: 0.9rem !important;
}

.lastLoginTime {
    position: absolute;
    font-size: .7rem;
    color: #858796 !important;
    bottom: 0;
    right: 0;
    width: max-content;
}

.rdt_TableHeadRow {
    background: #D3D3D3 !important;
    font-weight: bold;
    font-size: 1.1rem;
    border-top-left-radius: .3rem;
    border-top-right-radius: .3rem;
}

.rdt_TableCell {
    font-size: 1rem;
}

.auditTbl > .rdt_TableCell {

    align-items: baseline !important;
    margin-top:.5em;
    border-bottom-color: #1f222c;
    display:'table';

}

.li.groupHeading{
    color:#343434 !important;
}

.tableItemsHlink {
    cursor: pointer;
}

.linkCursor {
    cursor: pointer;
}


.toolTipSmall {
    cursor: pointer;
    font-size: 0.8rem;
    vertical-align: top;
    color: #0a53be;

}

@keyframes rotate360 {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(startYposition);
    }
    100% {
        opacity: 1;
        transform: translateY(endYposition);
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
        transform: translateY(startYposition);
    }
    100% {
        opacity: 0;
        transform: translateY(endYposition);
    }
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}


/*Toggle Buttons CSS*/
.react-toggle {
    touch-action: pan-x;
    display: inline-block;
    position: relative;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    padding: 0;
    margin-left: 0.4em;
    margin-right: 0.4em;
    margin-bottom: -0.3em;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.react-toggle--disabled {
    cursor: not-allowed;
    opacity: 0.5;
    -webkit-transition: opacity 0.25s;
    transition: opacity 0.25s;
}

.react-toggle-track {
    width: 50px;
    height: 24px;
    padding: 0;
    border-radius: 30px;
    background-color: #4D4D4D;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: #000000;
}

.react-toggle--checked .react-toggle-track {
    background-color: #19AB27;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: #128D15;
}

.react-toggle-track-check {
    position: absolute;
    width: 14px;
    height: 10px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    left: 8px;
    opacity: 0;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
    opacity: 1;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
}

.react-toggle-track-x {
    position: absolute;
    width: 10px;
    height: 10px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    right: 10px;
    opacity: 1;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
    opacity: 0;
}

.react-toggle-thumb {
    transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    position: absolute;
    top: 1px;
    left: 1px;
    width: 22px;
    height: 22px;
    border: 1px solid #4D4D4D;
    border-radius: 50%;
    background-color: #FAFAFA;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
    left: 27px;
    border-color: #19AB27;
}

.react-toggle--focus .react-toggle-thumb {
    -webkit-box-shadow: 0px 0px 3px 2px #0099E0;
    -moz-box-shadow: 0px 0px 3px 2px #0099E0;
    box-shadow: 0px 0px 2px 3px #0099E0;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
    -webkit-box-shadow: 0px 0px 5px 5px #0099E0;
    -moz-box-shadow: 0px 0px 5px 5px #0099E0;
    box-shadow: 0px 0px 5px 5px #0099E0;
}

.underline-input {
    width: 100%;
    padding: 2px 1px 2px;
    border: 0px transparent;
    background: white;
    border-bottom: 1px solid;
    border-radius: 0;
    outline: none;
    cursor: default;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;



}

.underline-input:focus {
    border: 0px solid white;
    border-bottom: 1px solid;
    cursor: default;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;



}

.noselect {
    cursor: default;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.require-input-alert{
    border: 1px solid red !important;
    -moz-box-shadow: inset 0 0 1px 1px red;
    -webkit-box-shadow: inset 0 0 1px 1px red;
    box-shadow: inset 0 0 1px 1px red;
}



.no-line-input {
    width: 100%;
    padding: 2px 1px 2px;
    border: 0px transparent;
    background: white;
    border-radius: 0
}

.ql-container {
    min-height: 20rem !important;
    max-height: 50rem !important;
}

.divHide {
    /*visibility: hidden;*/
    display: none;

}


.public-DraftEditor-content {
    overflow: auto;
    max-height: 80rem;

}

.div_Editor .DraftEditor-root .DraftEditor-editorContainer {
    min-height: 20rem;
    max-height: 45rem;

}

.rdw-editor-wrapper {
    border: grey solid 1px;
}

.w-100{
    border: solid grey 1px;
    overflow: auto;
    max-height: 45rem;
    min-height: 20rem;
}

.label-bottom-margin {
    marign-bottom: 1rem;
}

.am-input-parent {
    background-color: lightslategrey;
    color: white;
}

.am-input-parent-single {
    background-color: lightslategrey;
    color: white;
    margin-top: 2rem;
}

.am-input-child1-div {
    background-color: lightgrey;

}

.am-input-child1 {
    padding-left: 1rem !important;

}

.am-input-child2 {
    padding-left: 2rem !important;
}
.am-input-child3 {
    padding-left: 3.5rem !important;
    font-size : 1.1rem

}
.am-input-checkbox {
    width: 1rem;
    height: 1rem;
}

.am-tbl-width {
    width: inherit;
    width: 100%;
    table-layout: fixed;
}

.am-stick-row{
    position:sticky !important;
    top:0 !important;
    z-index : 3;
}

.exp-tbl-td td {
    padding: 0.4rem;
}

.equal-witdh-tbl {
    table-layout: fixed;
}

.center-aligned-tbl td {
    text-align: center;
}


.exp-text-area {
    min-height: 5rem;
}

.exp-input-parent-single {
    background-color: lightslategrey;
    color: white;
    margin-top: 1rem;
}

.small-characters {
    font-size: 1rem;

}

.floating-right {
    float: right;

}

.multiSelectContainer .searchBox {
    width: 40% !important;
}

.searchWrapper {
    border-radius:.2em;
}
.multiSelectContainer input{
    background: transparent;
    border: none;
    margin-top: 3px;
    margin-bottom: 3px;
    margin-left: 0.2em !important;
    color: #6e707e !important;
}

.singleChip {
    color: white !important;
}

.showPasswordInput {
    padding-right: 2.5rem !important;
}

.showPasswordButton {
    margin-left: -3.6rem;
    cursor: pointer;
}

.showPasswordButtonLogin {
    margin-left: -2rem;
    cursor: pointer;
}

.form-control-login{
    padding: .8em;
}

.disable_ms {
    opacity: 1 !important;
    background: #e6e9f2 !important;
    border-radius: .6rem !important;
    border: transparent !important;
    color: #6e707e !important;
    font-size: 1.1em !important;
    padding-right: 0px !important;
}

.disable_ms > .chip {
    background: #ffffff !important;
}

.password-alert-red {
    color: red;
    position: absolute;
    font-size: .7rem
}

.sideNavF1Lbl {
    border-bottom: 1px solid lightgrey;
    height : 2rem;
    margin-bottom:.2rem;
}

.sideNavF1LblSpan {
    margin-left: 1.2em;
    margin-bottom: .5em;
    font-size: 1.2em
}

.__react_component_tooltip{
    font-size: 1em !important;
}

.simpleMarginUpload{
    margin-right:1em !important;
}


.react-confirm-alert-button-group{
    justify-content: center !important;
}

.react-confirm-alert-button-group>button{
    font-size: 1rem !important;
    background: #344865 !important;
}
.my-tooltip .multi-line {
    text-align: left !important;
}


.react-confirm-alert-body > h1{
    font-size:1.7em !important;
}

.content_center {
    justify-content: center;
    align-items: center;
    font-size: 0.8em;
}

.ant-picker {
    width:100% !important;
}

.ant-pck-margin {
    margin-left: 0.8rem;
    margin-right: 0.8rem;

}

.btn.btn-primary:active,
.btn.btn-primary:hover,
.btn.btn-primary:focus {
    background-color: #344865 !important;
}

.taskHeader {
    background-color: #D3D3D3 !important;
    display: flex;
    align-items: center;
    text-align: left;
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 21px;
    border-top-left-radius: .3rem;
    border-top-right-radius: .3rem;
    padding: 10px 32px 10px 0px;
}
.taskChild {
    display: flex;
    align-items: center;
    text-align: left;
    font-size: 1.2rem;
    line-height: 21px;
    border-top-left-radius: .3rem;
    border-top-right-radius: .3rem;
    padding: 10px 32px 10px 0px;
}

.taskMonHeader {
    display: flex;
    align-items: center;
    text-align: left;
    line-height: 21px;
    padding: 2px 2px 2px 0px;
}

.taskMonTableHeader {
    display: flex;
    align-items: center;
    text-align: left;
    /*font-size: 1.2rem;*/
    line-height: 21px;
    border-top-left-radius: .3rem;
    border-top-right-radius: .3rem;
    padding: 10px 32px 10px 0px;
    background-color: rgb(227, 230, 240) !important;
}

.react-time-picker__wrapper{
    border:none !important;
}

.expandableDiv {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;

}


.enquiryFadeOutHide {
    visibility: hidden;
    display: none;
    animation: hide 0.1s linear;
    animation-fill-mode: forwards;

}

.enquiryFadeOutShow {
    visibility: visible;
    display: block;
    animation: show 0.1s linear;
    animation-fill-mode: forwards;

}

.arrow-left {
    float: left;
    border: solid black;
    height: .6em;
    width: .6em;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 0.2rem;
    margin-top: 0.5rem;
    /*margin-left: 1rem;*/
    transform: rotate(130deg);
    -webkit-transform: rotate(130deg);
    cursor: pointer;

}
@keyframes hide {
    0% {
        display: block;
        opacity: 1;
    }
    99% {
        display: block;
    }
    100% {
        display: none;
        opacity: 0;
    }
}

@keyframes show {
    0% {
        display: none;
        opacity: 0;
    }
    99% {
        display: block;
    }
    100% {
        display: block;
        opacity: 1;
    }
}

.btnExportCsv {
    float:right;
    background-color: #344865 !important;
    color: white;
    text-decoration: none;

}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}