:root {
    --primary-text: #2b2e38;
    --primary-text-dark: white;
    --secondary-text-dark: #c1c6cf;
    --light-gray: #c1c6cf;
    --light-gray-2: #fafbff;
    --light-gray-3: #f1f3f9;
    --light-gray-4: #7a8599;
    --dark-2: #212533;
    --dark-4: #2b2e38;
    --light-green-1: #8effd1;
    --light-green-3: #439782;
    --light-red-2: #e0484c;
    --blue-1: #00218f;
    --black-1: #1c1d24;
}

h4 {
    font-weight: 700;
}

.custom-nav .nav-link {
    color: var(--light-gray-4);
}

.custom-nav .nav-link.active {
    border: none !important;
    background-color: inherit !important;
    border-color: inherit !important;
    color: #439782;
    border-bottom: 3px solid #439782 !important;
}

.custom-nav .nav-link {
    border: 0px solid transparent !important;
}

.custom-form {
    width: 227.43px;
    height: 44px;
    /* Neutral/300 */
    border: 1px solid #cbd5e1;
    border-radius: 8px;
}

iframe {
    width: 100%;
    height: calc(100vh - 105px);
}

.tds-iframe-container > .row > div > .iframe-block {
    margin-left: -3%;
}

.iframe-container {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
}

.btn-primary-custom {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    padding: 16px;
    gap: 8px;

    width: 227.43px;
    height: 56px;

    /* Blue */
    color: white;
    background: #3268f6;
    border: none;
    border-radius: 8px;
}

.btn-primary-custom i {
    font-size: 16px;
}

select.underline {
    display: block;
    width: 100%;
    font-size: 1em;
    padding: 0.3em 3.5em 0.3em 0.5em;
    border: 0;
    border-bottom: 1px solid black;
    border-radius: 0;
    background: transparent;
    /** for the dropdown indicator */
    appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 1em;
}

div.custom-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #2b2e38;
}

textarea.custom {
    width: auto;
    height: auto;

    color: var(--dark-4);
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    padding: 10px 10px 10px 10px;
    border: 0;
    background: transparent;
    border: 1px solid var(--light-gray);
    border-radius: 8px;
}
input.custom {
    width: 227.43px;
    height: 44px;

    color: var(--dark-4);
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    padding: 10px 10px 10px 10px;
    border: 0;
    background: transparent;
    border: 1px solid var(--light-gray);
    border-radius: 8px;
}

select.custom {
    display: block;
    width: 227.43px;
    height: 44px;

    color: var(--dark-4);
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    padding: 10px 32px 10px 10px;
    border: 0;
    background: transparent;
    border: 1px solid var(--light-gray);
    border-radius: 8px;

    /** for the dropdown indicator */
    appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 20px;
}

select.custom option {
    /* background-color: red; */
}

.custom-form-group input {
    width: 296px;
    height: 56px;
    border: 1px solid #c1c6cf;
    border-radius: 8px;
}

.custom-form-group input::placeholder {
    color: var(--dark-4);
}

.btn.custom-btn-primary {
    width: 296px;
    height: 56px;
    color: #ffffff;
    background: #213A5E;
    border-radius: 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}

.btn.custom-btn-secondary {
    width: 296px;
    height: 56px;
    color: var(--dark-4);
    background: #f1f3f9;
    border-radius: 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}

.btn:hover {
    opacity: 0.9;
}

.custom-href {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #3268f6 !important;
    cursor: pointer;
}

.custom-href:hover {
    text-decoration: underline !important;
}

.form-check label {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #0f172a;
}

/* footer style */
footer {
    min-height: 50px;
    margin-top: auto;
    text-align: center;
    color: white;
    display: flex;
    justify-content: center;
}
footer .footer-container {
    width: 98%;
    border-top: 1px solid rgb(255, 255, 255, 0.25);
    padding-top: 16px;
}

footer .footer-container.protected {
    width: 100%;
    border-top: 1px solid var(--light-gray);
    color: #858796;
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
}

.showElement {
    display: block;
}

.hideElement {
    display: none;
}

/* /////////////// */
/* dark theme */
/* /////////////// */

.dark select.custom {
    /* color: white; */
    background-color: #ffffff;
    border: 1px solid var(--light-gray);
}

/* /////////////// */
/* dark theme end */
/* /////////////// */

/* custom scrollbar */
*::-webkit-scrollbar {
    width: 10px;
}

/* Track */
*::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
    background-color: #d6dee1;
}

/* Handle */
*::-webkit-scrollbar-thumb {
    background-color: white;

    border-radius: 20px;
    border: 3px solid transparent;
    background-clip: content-box;
}

/* Handle on hover */
*::-webkit-scrollbar-thumb:hover {
    /* background: #b30000;  */
}
/* custom scrollbar end*/

/* animation effect */
@-webkit-keyframes fadeInDown {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-20px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
    }
}

@keyframes fadeInDown {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@media only screen and (max-width: 1400px) {
    .tds-iframe-container > .row > div > .iframe-block {
        margin-left: -2%;
    }

    .custom-form-group input,
    .btn.custom-btn-primary,
    .btn.custom-btn-secondary {
        width: 100%;
    }
}

@media only screen and (max-width: 1000px) {
    .tds-iframe-container > .row > div > .iframe-block {
        margin-left: 0;
    }
}

@media only screen and (max-width: 600px) {
    .public-navbar-container {
        display: none;
    }
}

/* custom bootstrap grid */
/* BS4 Grid XXL breakpoint */

/* adding col-xxl */
.col-xxl,
.col-xxl-1,
.col-xxl-2,
.col-xxl-3,
.col-xxl-4,
.col-xxl-5,
.col-xxl-6,
.col-xxl-7,
.col-xxl-8,
.col-xxl-9,
.col-xxl-10,
.col-xxl-11,
.col-xxl-12,
.col-xxl-auto {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

@media (min-width: 1570px) {
    /* expanding BS4 max-width */
    .container {
        max-width: 1540px;
    }

    /* grid columns xxl */
    .col-xxl {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%;
    }
    .col-xxl-auto {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: none;
    }
    .col-xxl-1 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 8.333333%;
        flex: 0 0 8.333333%;
        max-width: 8.333333%;
    }
    .col-xxl-2 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }
    .col-xxl-3 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }
    .col-xxl-4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }
    .col-xxl-5 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }
    .col-xxl-6 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
    .col-xxl-7 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 58.333333%;
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
    }
    .col-xxl-8 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 66.666667%;
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }
    .col-xxl-9 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%;
    }
    .col-xxl-10 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }
    .col-xxl-11 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }
    .col-xxl-12 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }
}

.footer-customized{
    font-size: .8em;
    width: 100%;
    text-align: center;
    font-weight: 400;
    line-height: 21px;
    margin:1em;
    color:#858796;
    background-color: white;
}

.contentBg-customized{
    background-color: #f2f2f6
;
}
