.customer-profile-container > .row > .title {
    font-weight: 900;
    font-size: 24px;
    line-height: 36px;
    color: var(--dark-4);
    padding-bottom: 16px;
}

.customer-profile-container .search-container {
    display: flex;
    flex-wrap: wrap;
}
.customer-profile-container .input-container {
    display: flex;
    align-items: center;

    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: var(--dark-4);
    padding: 10px 32px 10px 0px;
}

.customer-profile-container .input-container .label {
    padding-right: 15px;
    min-width: 90px;
    font-weight: 600;
}

/* /////////////// */
/* dark theme */
/* /////////////// */

.dark .customer-profile-container > .row > .title,
.dark .customer-profile-container .input-container {
    color: white;
}

@media screen and (max-width: 600px) {
    .customer-profile-container .search-container,
    .customer-profile-container .input-container {
        flex-direction: column;
        align-items: baseline;
    }

}
