.react-table {
    border-spacing: 10px;
    border-collapse: collapse;
     border: 1px solid black;
    width: 100%;
}

.react-table > thead {
    background-color: white;
    color: #858796;
}

.react-table > thead > tr > th {
    padding: 13px 20px;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
}

/*.react-table > thead > tr > th:first-child {*/
/*    border-radius: 12px 0px 0px 0px;*/
/*}*/

/*.react-table > thead > tr > th:last-child {*/
/*    border-radius: 0px 12px 0px 0px;*/
/*}*/

.react-table > tbody > tr {
    border-bottom: 1px solid var(--light-gray);
}

.react-table > tbody > tr > td {
    margin: 0;
    padding: 13px 20px;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #2b2e38;
}

.react-table > tbody > tr:hover {
    background-color: #d8f1ff;
}

.react-table > tbody > tr button {
    font-size: 14px;
    width: 100px;
}

.react-table .cursor-pointer {
    cursor: pointer;
}

.react-table-contianer {
    /* background: rgb(246, 245, 245); */
}

.react-table-contianer .no-data {
    /* display: flex;
    justify-content: center;
    align-items: center; */
    text-align: center;
    width: 100%;
    height: 200px;
    background: rgb(246, 245, 245);
    border: 1px solid #fff;
    color: #bfc0c5;
    font-size: 17px;
}

.pagination-container {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
}

.pagination-container .page-info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 6px;
}

.pagination-container .pagination-list {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 6px;
}

.react-table-contianer .pagination-btn {
    padding: 6px 12px;
    gap: 6px;

    width: auto;
    height: 36px;

    background: #7a8599;
    border-radius: 8px;
    color: white;
    cursor: pointer;
}

.react-table-contianer .previous-btn,
.react-table-contianer .next-btn {
    border: none;
    background-color: inherit;

    font-weight: 400;
    font-size: 14px;
    line-height: 21px;

    color: #2b2e38;
}

.react-table-contianer .pagination-btn.current {
    background: #4f596c;
}

/* /////////////// */
/* dark theme */
/* /////////////// */

.dark .react-table > tbody > tr > td {
    background: white;
}
