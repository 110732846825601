.layout-container {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background-color: white;
}

.dark.layout-container {
    background-color: #141721;
}

.top-menu-page-container {
    /* height: 100vh; */
    overflow: auto;
}
